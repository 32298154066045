/** @jsx jsx */
import {
  jsx,
  Link as ExternalLink,
  Button as StyledButton,
  ButtonProps,
} from 'theme-ui'
import * as React from 'react'
import Link from 'next/link'

import triangle from '@styles/images/triangle.svg'

import {
  CloseIcon,
  PlayIcon,
  ArrowLeftIcon,
  HeartIcon,
  SearchIcon,
  LiveIcon,
  CountryIcon,
  PlanetIcon,
  SuitIcon,
  USBIcon,
  MutedIcon,
  SoundIcon,
} from '@components/Icons'

// createButtonSize('3em', 2, '0.56em');
const createButtonSize = (
  fontSize: any,
  height: any,
  px: any,
  sideWidth: any,
  overwrites: any = null
) => {
  const styles = {
    fontSize,
    position: 'relative',
    height,
    lineHeight: height,
    py: 0,
    px: px,
    mx: sideWidth,
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    fontFamily: 'mono',
    textTransform: 'uppercase',

    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      filter: 'brightness(90%)',
    },
    '&:disabled': {
      color: 'gray',
      cursor: 'not-allowed !important',
    },

    '&:before, &:after': {
      position: 'absolute',
      content: `''`,
      width: sideWidth,
      top: 0,
      bottom: 0,
      WebkitMask: `url(${triangle}) no-repeat center`,
      mask: `url(${triangle}) no-repeat center`,
      backgroundSize: '100% 100%',
      maskSize: '100% 100%',
    },
    '&:before': {
      left: `-${sideWidth}`,
    },
    '&:after': {
      right: `-${sideWidth}`,
      // transform: 'translateX(96%)',
      transform: 'scaleX(-1)',
    },
  }
  return { ...styles, ...overwrites }
}

const sizes = {
  small: createButtonSize(1, '1.6em', 2, '6px'),
  default: createButtonSize(2, '2.6em', 2, '10px'),
  large: createButtonSize(4, '4em', '1em', '18px'),
  ontour: createButtonSize(2, 'auto', '0.5em', '27px', {
    display: 'inline-block',
    lineHeight: 1.2,
    py: '0.8em',
  }),
}

const icons = {
  close: CloseIcon,
  play: PlayIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowLeftIcon,
  heart: HeartIcon,
  search: SearchIcon,
  live: LiveIcon,
  country: CountryIcon,
  planet: PlanetIcon,
  usb: USBIcon,
  suit: SuitIcon,
  muted: MutedIcon,
  sound: SoundIcon,
}

const iconColorsFromButtonVariant = {
  primary: 'blue',
  secondary: 'yellow',
  dark: 'yellow',
  yellow: 'red',
  transparent: 'yellow',
  sideMenu: 'green',
  disabled: 'gray',
}

type CustomButtonProps = ButtonProps & {
  size?: string
  label?: string
  icon?: string
}

export const Button = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  (
    {
      variant = 'primary',
      size = 'default',
      label,
      icon = null,
      disabled = false,
      children = null,
      ...rest
    },
    ref
  ) => {
    const buttonSize = size
    const sizeStyles = sizes[buttonSize] ?? sizes['default']
    const iconSize = buttonSize == 'large' ? 40 : 22

    const Icon = icon ? icons[icon] : null

    variant = disabled ? 'disabled' : variant

    sizeStyles['&:before'].bg = disabled ? 'dark' : variant
    sizeStyles['&:after'].bg = disabled ? 'dark' : variant

    return (
      <StyledButton
        disabled={disabled}
        variant={variant}
        sx={{
          ...sizeStyles,
        }}
        {...rest}
        ref={ref}
      >
        {Icon && (
          <Icon
            sx={{
              flex: '0 0 auto',
              width: 'auto',
              height: `${iconSize}px`,
              mr: label && '0.4em',
              transform: icon === 'arrowRight' && 'rotate(180deg)',
            }}
            color={iconColorsFromButtonVariant[variant]}
          />
        )}
        {label ? label : children}
      </StyledButton>
    )
  }
)

export const LinkButton = React.forwardRef<
  HTMLButtonElement,
  CustomButtonProps & { href?: string; target?: string; external?: boolean }
>(({ href = '', external, disabled = false, target, ...rest }, ref) => {
  if (disabled) {
    return <Button as="a" disabled={disabled} {...rest} ref={ref} />
  }
  if (external) {
    return (
      <ExternalLink href={href} sx={{ textDecoration: 'none' }}>
        <Button {...rest} ref={ref} />
      </ExternalLink>
    )
  }
  return (
    <Link href={href} passHref>
      <Button as="a" {...rest} ref={ref} target={target} />
    </Link>
  )
})
