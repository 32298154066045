import * as React from 'react'
import Head from 'next/head'

export const siteTitle = 'Cinekid Play'
const defaultDescription =
  'Cinekid Play is het nieuwe online VOD platform van Cinekid.'

export const Metadata = ({
  children,
  title = siteTitle,
  description = defaultDescription,
}: {
  children?: React.ReactNode
  title?: string
  description?: string
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/images/icons/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/icons/favicon-16x16.png"
      />
      <link rel="manifest" href="/images/icons/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/images/icons/safari-pinned-tab.svg"
        color="#5761ec"
      />
      <link rel="shortcut icon" href="/images/icons/favicon.ico" />
      <meta name="msapplication-TileColor" content="#f1d710" />
      <meta
        name="msapplication-config"
        content="/images/icons/browserconfig.xml"
      />
      <meta name="theme-color" content="#ffffff" />
      {children}
    </Head>
  )
}
