const fontStack =
  '"telegraf", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif'

const colors = {
  white: '#ffffff',
  black: '#242528',
  pitchBlack: '#000000',
  gray: '#959BAD',
  lightgray: '#EAEBEF',
  blue: '#5761EC',
  navy: '#2A246E',
  navy3d: '#020259',
  red: '#FB2855',
  yellow: '#F1D710',
  green: '#2AEB9E',
  pink: '#F33FA2',
}

const themeColors = {
  ...colors,
  text: {
    subtle: colors.gray,
    body: colors.black,
    heading: colors.black,
  },
  background: colors.blue,
  primary: colors.green,
  secondary: colors.red,
  error: colors.red,
  dark: colors.black,
}

const theme = {
  initialColorModeName: 'light',
  useColorSchemeMediaQuery: false,
  breakpoints: ['40em', '52em', '64em', '80em'], // 640, 832, 1024, 1280
  space: {
    '0': 0,
    '1': 4,
    '2': 8,
    '3': 16,
    '4': 20,
    '5': 32,
    '6': 40,
    '7': 64,
    '8': 92,
  },
  sizes: {
    maxTextWidth: 800,
  },
  colors: themeColors,
  radii: [0, 2, 4, 6, 16, 26, 32],
  fonts: {
    body: fontStack,
    heading: fontStack,
    mono: '"supply", monospace,' + fontStack,
  },
  fontSizes: {
    '0': 13,
    '1': 15,
    '2': 18,
    '3': 20,
    '4': 24,
    '5': 36,
    '6': 54,
    '7': 82,
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    large: 1.6,
    heading: 1.125,
  },
  buttons: {
    primary: {
      bg: 'green',
      color: colors.black,
      cursor: 'pointer',
    },
    secondary: {
      bg: 'red',
      color: 'white',
      cursor: 'pointer',
    },
    lightgray: {
      bg: 'lightgray',
      color: 'dark',
      cursor: 'pointer',
    },
    dark: {
      bg: 'black',
      color: 'yellow',
      cursor: 'pointer',
    },
    yellow: {
      bg: 'yellow',
      color: 'black',
      cursor: 'pointer',
    },
    transparent: {
      bg: 'transparent',
      color: 'primary',
      cursor: 'pointer',
    },
    sideMenu: {
      bg: 'transparent',
      color: 'yellow',
      cursor: 'pointer',
    },
    disabled: {
      bg: 'black',
      color: 'gray',
      cursor: 'not-allowed',
    },
    menu: {
      cursor: 'pointer',
      outline: 'none',
      position: 'relative',
      background: 'transparent',
      width: '40px',
      height: '40px',
      px: '5px',
      py: '8px',
      '> div': {
        position: 'absolute',
        top: '8px',
        transition: 'all 0.1s linear',
        width: '30px',
        height: '3px',
        background: 'yellow',
        borderRadius: '3px',
        '&:nth-of-type(1)': {
          top: '8px',
        },
        '&:nth-of-type(2)': {
          top: '18px',
        },
        '&:nth-of-type(3)': {
          top: '28px',
        },
      },
    },
    'menu--open': {
      cursor: 'pointer',
      outline: 'none',
      position: 'relative',
      background: 'transparent',
      width: '40px',
      height: '40px',
      px: '5px',
      py: '8px',
      '> div': {
        position: 'absolute',
        top: '8px',
        transition: 'all 0.1s linear',
        width: '30px',
        height: '3px',
        background: 'yellow',
        borderRadius: '3px',
        '&:nth-of-type(1)': {
          top: '18px',
          transform: 'rotate(-45deg)',
        },
        '&:nth-of-type(2)': {
          top: '18px',
          transform: 'scaleX(0)',
        },
        '&:nth-of-type(3)': {
          top: '18px',
          transform: 'rotate(45deg)',
        },
      },
    },
  },
  badges: {
    primary: {
      bg: 'green',
    },
    secondary: {
      bg: 'red',
    },
    dark: {
      bg: 'black',
    },
  },
  text: {
    default: {
      color: 'text',
      fontSize: 3,
      lineHeight: 'body',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      textTransform: 'uppercase',
      medium: {
        fontSize: [2, 4],
        mb: 2,
        textTransform: 'uppercase',
      },
      large: {
        fontSize: [3, '32px'],
        mb: 2,
        textTransform: 'uppercase',
      },
      extraLarge: {
        fontSize: [4, '48px'],
        mb: 2,
        textTransform: 'uppercase',
      },
    },
    itemTitle: {
      fontSize: 3,
      fontWeight: 'heading',
      textTransform: 'uppercase',
      mb: '0',
    },
    itemDescription: {
      mt: 2,
      fontSize: 1,
      lineHeight: 'body',
    },
    headingMedium: {
      fontSize: [4, '30px'],
      fontWeight: 'heading',
      textTransform: 'uppercase',
      mb: '0.5em',
    },
    body: {
      fontSize: 2,
      lineHeight: 'body',
      small: {
        fontSize: 1,
        lineHeight: 'body',
      },
      large: {
        fontSize: [3, 4],
        lineHeight: 'body',
        mb: '0.5em',
      },
    },
    small: {
      fontSize: 1,
      lineHeight: 'body',
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.025em',
    },
    title: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      textTransform: 'uppercase',
      color: 'primary',
      fontSize: [4, 5],
      mb: 3,
      textAlign: 'center',
    },
    titleBig: {
      fontSize: 6,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      lineHeight: '1em',
      textAlign: 'center',
    },
    intro: {
      fontSize: [3, 4],
      lineHeight: 'body',
    },
    mono: {
      fontFamily: 'mono',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      body: {
        fontFamily: 'mono',
        textTransform: 'uppercase',
        fontWeight: 'normal',
        fontSize: 2,
      },
      small: {
        fontFamily: 'mono',
        textTransform: 'uppercase',
        fontWeight: 'normal',
        fontSize: 1,
        lineHeight: 'body',
      },
    },
    date: {
      fontSize: [1, 2],
      lineHeight: '1.1em',
      fontFamily: 'mono',
      textTransform: 'uppercase',
      minWidth: '7.8em',
      textAlign: ['left', null, null, 'right'],
    },
  },
  images: {
    avatar: {
      width: [100, 140],
      height: [100, 140],
      borderRadius: 99999,
    },
  },
  forms: {
    input: {
      width: '100%',
      color: 'black',
      bg: 'white',
      border: 'none',
      height: '50px',
      borderRadius: 1,
      px: 3,
      fontFamily: 'body',
      '::placeholder': {
        color: 'gray',
      },
      '&:focus': {
        outline: 'none',
        boxShadow: (theme) => `0 0 0 2px ${theme.colors.yellow} inset`,
      },
    },
  },
  alerts: {
    primary: {
      bg: 'dark',
      color: 'white',
      p: 3,
      borderRadius: 0,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary',
      textAlign: 'center',
      fontFamily: 'mono',
    },
    error: {
      bg: 'dark',
      color: 'red',
      p: 3,
      borderRadius: 0,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'red',
      textAlign: 'center',
      fontFamily: 'mono',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      color: 'text',
      bg: 'background',
      p: 0,
    },
    h1: {
      fontSize: [4, 5, 6],
      color: 'primary',
      lineHeight: 1.2,
    },
    a: {
      color: 'primary',
      textDecoration: 'underline',
      '&:hover': {
        color: 'text',
        textDecoration: 'none',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    img: {
      maxWidth: '100%',
      display: 'block',
    },
  },
}

// Aliases
theme.fontSizes.small = theme.fontSizes[0]
theme.fontSizes.regular = theme.fontSizes[2]
theme.fontSizes.large = theme.fontSizes[4]

theme.space.gutter = theme.space[2]
theme.space.xxsmall = theme.space[1]
theme.space.xsmall = theme.space[2]
theme.space.small = theme.space[3]
theme.space.medium = theme.space[4]
theme.space.large = theme.space[5]
theme.space.xlarge = theme.space[6]
theme.space.xxlarge = theme.space[7]

theme.buttons.green = theme.buttons.primary
theme.buttons.red = theme.buttons.secondary

export default theme
